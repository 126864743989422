import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { Metrics } from '../Metrics';

const GetIncidentTotalResponseEffort = `#graphql
  query ($startDate: String!, $endDate: String!) {
    insights {
      incidents {
        totalResponseEffort(startDate: $startDate, endDate: $endDate)
      }
    }
  }
`;

export const IncidentTotalResponseEffortMetrics = () => {
  const endDate = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery({
    query: GetIncidentTotalResponseEffort,
    variables: {
      startDate,
      endDate
    }
  });

  const incidentTotalResponseEffort = data?.insights?.incidents?.totalResponseEffort;

  return (
    <Metrics
      name="Total Response Effort"
      value={incidentTotalResponseEffort || '-'}
      footer="Metrics calculated up to yesterday"
      isFetching={fetching}
      tooltipTitle="Total sum of time involved in an incident, measured from acknowledgment to resolution."
    />
  );
};
