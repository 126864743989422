import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { Metrics } from '../Metrics';

export const MTTR = () => {
  const GetIncidentsTotal = `#graphql
    query (
      $startDate: String! 
      $endDate: String!      
    ) {
      insights {
        incidents {
          mttr(startDate: $startDate, endDate: $endDate)
        }      
      }
    }
  `;

  const endDate = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery({
    query: GetIncidentsTotal,
    variables: {
      startDate,
      endDate
    }
  });

  const mttr = data?.insights?.incidents?.mttr;

  return (
    <Metrics
      name="MTTR"
      value={mttr || '-'}
      footer="Metrics calculated up to yesterday"
      isFetching={fetching}
      tooltipTitle="Mean Time to Resolve (MTTR) is the average time from the trigger of an incident to its resolution."
    />
  );
};
