import { Link } from 'react-router-dom';

import { Button } from 'components/Button';

export const AddMemberButton = () => {
  return (
    <Button component={Link} to="#" title="Add Member">
      Member
    </Button>
  );
};
