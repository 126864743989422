import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  paperTop: {
    padding: theme.spacing(2),
    height: '130px'
  },
  paperTextMiddle: {
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gray[700],
    marginTop: theme.spacing(1)
  },
  paperTextBottom: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    color: '#898989',
    fontWeight: theme.typography.fontWeightRegular
  },
  tooltipIcon: {
    fontSize: theme.typography.body1.fontSize
  }
}));
