import { Box, Divider, Typography } from '@material-ui/core';

import NotifySwitch from 'components/NotificationRules/NotifySwitch';
import { Contacts } from 'views/Account/components/ContactMethod';

import { useStyles } from './styles';

const NotificationsRules = ({ contactMethods }: { contactMethods: Contacts }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
      <Typography className={classes.subTitle} variant="h3">
        When an alert is opened:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <NotifySwitch contacts={contactMethods} method="email" />
            <NotifySwitch contacts={contactMethods} method="whatsapp" />
            <NotifySwitch contacts={contactMethods} method="voice" />
            <NotifySwitch contacts={contactMethods} method="whatsapp" />
            <NotifySwitch contacts={contactMethods} method="sms" />
            <NotifySwitch contacts={contactMethods} method="telegram" />
            <NotifySwitch contacts={contactMethods} method="app" />
          </Box>
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        When an incident is opened:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <NotifySwitch contacts={contactMethods} method="email" />
            <NotifySwitch contacts={contactMethods} method="whatsapp" />
            <NotifySwitch contacts={contactMethods} method="voice" />
            <NotifySwitch contacts={contactMethods} method="whatsapp" />
            <NotifySwitch contacts={contactMethods} method="sms" />
            <NotifySwitch contacts={contactMethods} method="telegram" />
            <NotifySwitch contacts={contactMethods} method="app" />
          </Box>
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        Incident response notification management:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <NotifySwitch
              contacts={contactMethods}
              method="all"
              label="Receiving notifications for alerts and incidents enabled"
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationsRules;
