import Breadcrumb from 'components/Breadcrumb';
import { Title } from 'components/Title';
import useQueryParams from 'helpers/useQueryParams';

import { AddMemberButton } from './components/AddMemberButton';
import { TeamTabs } from './Tabs';

const Team = () => {
  const queryParams = useQueryParams();

  const tab = queryParams.get('tab');

  return (
    <>
      <Breadcrumb
        items={[
          { label: 'People' },
          { label: 'Teams Center', link: '/teams' },
          { label: 'Product Team' }
        ]}
      />
      <Title title="Product Team">{tab === 'member' && <AddMemberButton />}</Title>
      <TeamTabs />
    </>
  );
};

export default Team;
