import { Card, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { MetricsTitle } from '../MetricsTitle';

import { useStyles } from './styles';

export const Metrics = ({
  name,
  value,
  footer,
  isFetching = true,
  tooltipTitle
}: {
  name: string;
  value: number | string;
  footer?: string;
  isFetching?: boolean;
  tooltipTitle?: string;
}) => {
  const classes = useStyles();

  return (
    <Card elevation={1} className={classes.paperTop} variant="outlined">
      <MetricsTitle title={name} tooltipTitle={tooltipTitle} />

      <Typography variant="h5" className={classes.paperTextMiddle}>
        {isFetching ? <Skeleton variant="text" width="100%" /> : value || '-'}
      </Typography>
      <Typography variant="body2" className={classes.paperTextBottom}>
        {footer}
      </Typography>
    </Card>
  );
};
