import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: theme.spacing(5.5),
    '& fieldset': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.gray[200]
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    }
  },
  badgeContent: {
    backgroundColor: theme.palette.primary.main,
    minWidth: 16,
    height: 16
  },
  button: {
    minWidth: 115
  }
}));
