import React, { ReactNode } from 'react';

import { Chip, makeStyles, TextFieldProps, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Autocomplete } from '@material-ui/lab';
import { Controller, UseFormMethods } from 'react-hook-form';

import { Input } from '../Input';

type SelectProps = {
  control: UseFormMethods['control'];
  options: {
    label: string;
    value: string;
    type?: string;
    helperText?: ReactNode;
  }[];
} & TextFieldProps;

const useStyles = makeStyles(theme => ({
  helperText: {
    color: grey[500],
    marginLeft: theme.spacing(),
    textTransform: 'capitalize'
  }
}));

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function MultiSelect({ options, control, ...textFieldProps }: SelectProps) {
  const classes = useStyles();
  const { name, ...inputProps } = textFieldProps;

  return (
    <Controller
      control={control}
      name={name!}
      defaultValue={textFieldProps.defaultValue}
      render={({ value, onChange }) => {
        const orderedOptions = options.filter(
          option =>
            !value?.find((responder: { value: string }) => responder?.value === option.value)
        );

        return (
          <Autocomplete
            multiple
            value={value}
            onChange={(_, value) => onChange(value)}
            getOptionLabel={option => option.label}
            options={orderedOptions}
            size="small"
            fullWidth
            ChipProps={{
              color: 'secondary'
            }}
            renderTags={(value, getTagProps: (param: any) => any) =>
              value.map((option, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Tooltip
                    title={
                      option.helperText
                        ? `${option.label} (${capitalizeFirstLetter(option.helperText)})`
                        : `${option.label} (Team)`
                    }
                    arrow>
                    <Chip
                      size="small"
                      color="secondary"
                      label={option.label}
                      key={key}
                      {...tagProps}
                    />
                  </Tooltip>
                );
              })
            }
            renderOption={option => (
              <React.Fragment>
                {option.label}
                <span className={classes.helperText}>{option.helperText}</span>
              </React.Fragment>
            )}
            renderInput={params => <Input {...params} {...inputProps} />}
          />
        );
      }}
    />
  );
}
