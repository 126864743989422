import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { Metrics } from '../Metrics';

const GetMaxLatencyQuery = `#graphql
  query(
    $startDate: String!
    $endDate: String!
  ){
    insights{
      monitoringPerformance {
        maxLatency(startDate: $startDate, endDate: $endDate)
      }
    }
  }
`;

export const MaxLatencyMetrics = () => {
  const endDate = dayjs().format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery({
    query: GetMaxLatencyQuery,
    variables: {
      startDate,
      endDate
    }
  });

  const maxLatency = data?.insights?.monitoringPerformance?.maxLatency;

  const formatMaxLatency = () => {
    if (typeof maxLatency !== 'number' || maxLatency === -1) {
      return '-';
    }

    return `${maxLatency}ms`;
  };

  return (
    <Metrics
      name="Max Hour Latency"
      value={formatMaxLatency()}
      tooltipTitle="Maximum response time (latency) in monitoring."
      footer="Real-time data"
      isFetching={fetching}
    />
  );
};
