import { useState } from 'react';

import { Box, Link, Switch, Typography } from '@material-ui/core';

import Dialog from 'components/Dialog';

import { Contacts } from '../../../views/Account/components/ContactMethod';

import { useStyles } from './styles';

type Props = {
  method: Method;
  contacts: Contacts;
  label?: string;
};

type Method = 'whatsapp' | 'telegram' | 'email' | 'sms' | 'voice' | 'app' | 'all';

type StaticContent = Record<
  Method,
  {
    label?: string;
  }
>;

const staticContent: StaticContent = {
  whatsapp: {
    label: 'WhatsApp'
  },
  telegram: {
    label: 'Telegram'
  },
  email: {
    label: 'e-mail'
  },
  sms: {
    label: 'SMS'
  },
  voice: {
    label: 'voice'
  },
  app: {
    label: 'Mobile App'
  },
  all: {}
};

const DISABLE_MESSAGE = `By disabling this channel, you will stop receiving notifications sent through it until it is reactivated. Do you wish to continue?`;
const ACTIVE_MESSAGE = `By activating this channel, you'll start receiving notifications sent through it. Do you wish to continue?`;

const NotifySwitch = ({ method, contacts, label }: Props) => {
  const classes = useStyles();
  const [currentContact] = contacts.filter(contact => contact.method === method);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(currentContact.active);

  const content = staticContent[method];

  const onToggleStatus = () => {
    try {
      // Show error message in random times
      if (currentContact.method === 'whatsapp' && status) {
        setOpenErrorModal(true);
      } else {
        setOpenModal(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const disableActionModal = () => {
    setStatus(false);
    onCloseModal();
  };

  const enableActionModal = () => {
    setStatus(true);
    onCloseModal();
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Switch
          size="small"
          color="primary"
          //   value={status}
          checked={status}
          onChange={onToggleStatus}
        />
        {label && <Typography className={classes.label}>{label}</Typography>}
        {!label && (
          <Typography className={classes.label}>
            Notify me at {content.label}: {currentContact.value}
          </Typography>
        )}
      </Box>
      <Dialog
        title={`${status ? 'Disable' : 'Enable'} notifications`}
        description={status ? DISABLE_MESSAGE : ACTIVE_MESSAGE}
        open={openModal}
        onClose={onCloseModal}
        actions={[
          {
            label: 'Cancel',
            action: onCloseModal,
            variant: 'outlined'
          },
          {
            label: status ? 'Disable' : 'Enable',
            action: status ? disableActionModal : enableActionModal
          }
        ]}>
        <></>
      </Dialog>
      <Dialog
        title={`Error disabling ${content.label} notification`}
        description={
          <>
            Unable to disable this notification channel as it is linked to an active on-call shift.
            To proceed, first go to Call-Rotation, replace the channel, and then return to
            Notification Rules.If you have any questions, access the{' '}
            <Link>
              <strong>documentation</strong>
            </Link>{' '}
            and follow the steps.
          </>
        }
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        actions={[
          {
            label: 'Cancel',
            action: () => setOpenErrorModal(false),
            variant: 'outlined'
          },
          {
            label: status ? 'Disable' : 'Enable',
            action: status ? disableActionModal : enableActionModal,
            disabled: true
          }
        ]}>
        <></>
      </Dialog>
    </>
  );
};

export default NotifySwitch;
