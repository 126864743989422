import { ComponentType } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  SvgIconProps
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { useStyles } from './styles';

type NotificationCardProps = {
  title: string;
  description: string;
  icon: ComponentType<SvgIconProps>;
  integrated?: boolean;
};

const NotificationCard = ({ title, description, icon, integrated }: NotificationCardProps) => {
  const classes = useStyles();

  const Icon = icon;

  return (
    <>
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.cardContent}>
          <Box className={classes.cardTop} gridGap={8}>
            <Icon style={{ width: '32px', height: '32px' }} />
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Typography variant="subtitle2" className={classes.description}>
            {description}
          </Typography>

          <Divider className={classes.divider} />

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>0 Resources</Typography>

            <Box display="flex" alignItems="center" gridGap={8}>
              <Chip
                data-integrated={integrated}
                label={integrated ? 'Integrated' : 'Not Integrated'}
                className={classes.chip}
              />
              <Button variant="outlined" color="primary">
                <MoreVert color="primary" />
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default NotificationCard;
