import { severityOptions } from 'constants/severityOptions';

import { Box, Button, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Controller, UseFormMethods } from 'react-hook-form';

import { RespondersSelect } from 'components/RespondersSelect';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';
import { TeamsSelect } from 'views/ServicesHub/components/TeamsSelect';

import { FormProps } from '../..';

export type IncidentFormData = {
  incident: {
    severity: string;
    intervalHits: number;
    intervalFailures: number;
    failuresToOpenIncident: number;
    hitsToCloseIncident: number;
    teams: { value: string; label: string }[];
    responders: { value: string; label: string; type: string }[];
    enable: boolean;
    type: string;
  };
};

export function IncidentForm({ form }: FormProps) {
  const { register, control, errors: formErrors } = form as UseFormMethods<{
    rules: IncidentFormData;
  }>;

  const shouldUseRespondersInput = useFeatureFlagUnleash('useRespondersInput', {
    queryString: true
  });

  const isEdit = window.location.pathname.includes('edit');

  const isIncidentEnabled = form.watch('rules.incident.enable');

  const errors = formErrors?.rules?.incident
    ? Object.entries(formErrors?.rules?.incident).reduce((_errors, [key, value]) => {
        _errors[`rules.incident.${key}`] = value;

        return _errors;
      }, {} as Record<string, any>)
    : {};

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Select
        options={severityOptions}
        control={control}
        name="rules.incident.severity"
        label="Severity"
        required={isIncidentEnabled}
      />

      <Box display="flex" gridGap="1rem">
        <Input
          errors={errors}
          label="Check Interval in seconds"
          type="number"
          name="rules.incident.intervalFailures"
          inputRef={register}
          required={isIncidentEnabled}
        />
        <Input
          errors={errors}
          label="Failures to open automatic incident"
          type="number"
          name="rules.incident.failuresToOpenIncident"
          inputRef={register}
          required={isIncidentEnabled}
          inputProps={{
            min: 1
          }}
        />
      </Box>

      <Box display="flex" gridGap="1rem">
        <Input
          errors={errors}
          label="Check Interval in seconds"
          type="number"
          name="rules.incident.intervalHits"
          inputRef={register}
          required={isIncidentEnabled}
        />
        <Input
          errors={errors}
          label="Hits to close automatic incident"
          type="number"
          name="rules.incident.hitsToCloseIncident"
          inputRef={register}
          required={isIncidentEnabled}
          inputProps={{
            min: 1
          }}
        />
      </Box>

      {shouldUseRespondersInput ? (
        <RespondersSelect
          control={control}
          name="rules.incident.responders"
          required={false}
          multiSelect={true}
        />
      ) : (
        <TeamsSelect
          control={control}
          name="rules.incident.teams"
          required={false}
          multiSelect={true}
        />
      )}

      <Controller
        control={control}
        type="checkbox"
        name="rules.incident.enable"
        render={({ value, onChange }) => {
          if (isEdit && value) {
            return (
              <Button
                variant="outlined"
                startIcon={<Delete />}
                onClick={() => onChange(false)}
                fullWidth>
                Remove current settings
              </Button>
            );
          }

          return (
            <FormGroup>
              <FormControlLabel
                label="Enable to set up automatic incidents opening"
                control={
                  <Switch
                    color="primary"
                    value={value}
                    checked={value}
                    onChange={(_, val) => onChange(val)}
                  />
                }
              />
            </FormGroup>
          );
        }}
      />

      <input type="hidden" name="rules.incident.type" value="incident" ref={register} />
    </Box>
  );
}
