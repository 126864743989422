import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { Metrics } from '../Metrics';

const GetUptimeInsightsQuery = `#graphql
    query(
      $startDate: String!
      $endDate: String!
    ){
        insights{
            monitoringPerformance {
                uptime(startDate: $startDate, endDate: $endDate)
            }
        }
    }
`;

export const UptimeMetrics = () => {
  const endDate = dayjs().format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery<{
    insights: { monitoringPerformance: { uptime: number } };
  }>({
    query: GetUptimeInsightsQuery,
    variables: {
      startDate,
      endDate
    }
  });

  const uptime = data?.insights?.monitoringPerformance?.uptime;

  const formatUptime = () => {
    if (typeof uptime !== 'number' || uptime === -1) {
      return '';
    }

    return `${uptime}%`;
  };

  return (
    <Metrics
      name="Uptime"
      value={formatUptime()}
      tooltipTitle="Percentage of availability of the monitored resource"
      footer="Real-time data"
      isFetching={fetching}
    />
  );
};
