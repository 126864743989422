import { Box } from '@material-ui/core';

import DiscordIcon from 'icons/DiscordFilled';
import Email from 'icons/Email';
import GoogleChat from 'icons/GoogleChatColored';
import MicrosoftTeams from 'icons/MicrosoftTeams';
import SlackColouredIcon from 'icons/SlackColoured';

import NotificationCard from './NotificationCard';

const IntegrationTab = () => {
  return (
    <Box display="flex" flexWrap="wrap" gridGap={20}>
      <NotificationCard
        title="Discord"
        description="Integrate with Discord and receive your notifications of alerts and incidents."
        icon={DiscordIcon}
        integrated={true}
      />
      <NotificationCard
        title="Slack"
        description="Before connecting as a notification channel in the team, go to Service Hub and join the organization."
        icon={SlackColouredIcon}
      />
      <NotificationCard
        title="Microsoft Teams"
        description="Integrate with Microsoft Teams and receive your notifications of alerts and incidents."
        icon={MicrosoftTeams}
      />
      <NotificationCard
        title="Google Chat"
        description="Integrate with Google Chat and receive your notifications of alerts and incidents."
        icon={GoogleChat}
        integrated={true}
      />
      <NotificationCard
        title="E-mail group"
        description="Receive your notifications of alerts and incidents in your corporate email."
        icon={Email}
      />
    </Box>
  );
};

export default IntegrationTab;
