import { ChangeEvent, useCallback, useRef, useState } from 'react';

import { Box, CircularProgress, Divider, TextField, Typography, useTheme } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Team } from 'types/external/Team';

import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/Button';
import { buildElasticQuery } from 'helpers/buildElasticQuery';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { useTeams } from 'hooks/queriesGraphQL/useTeamsV2';
import useDebounce from 'hooks/useDebounce';
import { usePermission } from 'hooks/usePermission';

import { MenuActions } from './components/MenuActions';

const PER_PAGE_OPTIONS = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500;

export function TeamList() {
  const globalClasses = useGlobalStyles();
  const theme = useTheme();

  const history = useHistory();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);

  const hasCreateTeamPermission = usePermission('TeamController-post-/teams');

  const [{ fetching, data, error }, refetch] = useTeams({
    page,
    perPage,
    query: buildElasticQuery({
      search
    })
  });

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const refresh = useCallback(() => {
    setTimeout(() => {
      refetch({ requestPolicy: 'network-only' });
    }, 1000);
  }, [refetch]);

  const COLUMNS: TableColumn<Team>[] = [
    {
      name: 'Name',
      selector: row => row.name
    },
    {
      name: 'Members',
      selector: row => row.members.length
    },
    {
      name: 'Actions',
      right: true,
      cell: row => <MenuActions id={row.id} name={row.name} refetch={() => refresh()} />
    }
  ];

  const TeamsResult = () => {
    if (error) {
      return (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#000000de',
            fontSize: '16px'
          }}>
          Error on load data
        </Typography>
      );
    }

    return (
      <>
        {data?.getTeams?.data?.length ? <Divider /> : null}

        <DataTable
          columns={COLUMNS}
          data={data?.getTeams?.data ?? []}
          noDataComponent="No results found"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeSize}
          paginationComponentOptions={{
            rowsPerPageText: 'Items per page:',
            rangeSeparatorText: 'of',
            noRowsPerPage: false
          }}
          paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
          paginationPerPage={perPage}
          paginationTotalRows={data?.getTeams?.total}
          paginationServer
          pagination
          progressComponent={<CircularProgress color="primary" size="3rem" />}
          progressPending={fetching}
          highlightOnHover
          striped
          pointerOnHover={true}
          onRowClicked={team => history.push(`teams/${team.id}`)}
        />
      </>
    );
  };

  return (
    <>
      <Breadcrumb items={[{ label: 'People' }, { label: 'Team Center' }]} />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h4">Teams Center</Typography>

        <Box>
          <Button
            component={Link}
            to="/teams/new"
            title="Create new team"
            disabled={!hasCreateTeamPermission}>
            Team
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={7.5}
        gridGap={theme.spacing(1.5)}
        {...{
          ref: searchContainerRef
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleChangeSearch}
          placeholder="Try to search by name"
          InputProps={{
            endAdornment: <Search />,
            className: globalClasses.input
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
          fullWidth
        />
      </Box>

      {TeamsResult()}
    </>
  );
}
