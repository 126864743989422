import { Box } from '@material-ui/core';

import NotificationsRules from 'components/NotificationRules';
import { Contacts } from 'views/Account/components/ContactMethod';

const ruleMock: Contacts = [
  {
    method: 'whatsapp',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'email',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'voice',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'sms',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'telegram',
    value: '8928383',
    active: true,
    verified: true
  },
  {
    method: 'app',
    value: 'iphone j. Laricia - iphone 12 - v.1',
    active: true,
    verified: true
  },
  {
    method: 'all' as any,
    value: '',
    active: true,
    verified: true
  }
];

const NotificationRulesTab = () => {
  return (
    <Box>
      <NotificationsRules contactMethods={ruleMock} />
    </Box>
  );
};

export default NotificationRulesTab;
