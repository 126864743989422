import DataTable, { TableColumn } from 'react-data-table-component';

import { MenuActions } from '../MenuActions';

const mockMembers = [
  { id: 1, name: 'Hugo', role: { name: 'member' } },
  { id: 2, name: 'Raphael', role: { name: 'owner' } },
  { id: 3, name: 'Rafael', role: { name: 'admin' } }
];

const MemberList = () => {
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: row => row.name
    },
    {
      name: 'Role',
      selector: row => row.role.name
    },
    {
      name: 'Actions',
      right: true,
      cell: row => <MenuActions />
    }
  ];

  return (
    <DataTable
      columns={columns}
      data={mockMembers}
      noDataComponent="No results found"
      highlightOnHover
      pagination
    />
  );
};

export default MemberList;
