import { Box, Typography } from '@material-ui/core';
import ReactCodeInput from 'react-verification-code-input';

import { Button } from 'components/Button';
import Dialog from 'components/Dialog';

import FormWithPhoneNumber from '../../GenericForms/FormWithPhoneNumber';
import FormWithTextFieldInput from '../../GenericForms/FormWithTextFieldInput';
import { Props as GenericFormsProps } from '../../GenericForms/types';
import { PropsCreate, StaticContentsCreate } from '../types';

const staticContents: StaticContentsCreate = {
  whatsapp: {
    title: 'Add your number to receive WhatsApp notifications',
    description: 'Enter your phone number to receive notifications via WhatsApp from our platform.',
    checkboxLabel: 'Use this number to receive notifications via SMS and WhatsApp',
    Form: (props: GenericFormsProps) => <FormWithPhoneNumber {...props} />
  },
  voice: {
    title: 'Add your number to receive voice notifications',
    description: 'Enter your phone number to receive notifications via voice from our platform.',
    checkboxLabel: 'Use this number to receive notifications via SMS and WhatsApp',
    Form: (props: GenericFormsProps) => <FormWithPhoneNumber {...props} />
  },
  sms: {
    title: 'Add your number to receive SMS notifications',
    description: 'Enter your phone number to receive notifications via SMS from our platform.',
    checkboxLabel: 'Use this number to receive notifications via SMS and WhatsApp',
    Form: (props: GenericFormsProps) => <FormWithPhoneNumber {...props} />
  },
  telegram: {
    title: 'Add your Telegram ID to receive notifications',
    description: 'Enter your Telegram ID to receive notifications from our platform.',
    inputLabel: 'Telegram ID',
    Form: (props: GenericFormsProps) => <FormWithTextFieldInput {...props} />
  },
  email: {
    title: 'Add your email to receive notifications',
    description: 'Enter your email address to receive notifications from our platform.',
    inputLabel: 'Email',
    Form: (props: GenericFormsProps) => <FormWithTextFieldInput {...props} />
  }
};

export const CreateMethodDialog = ({
  onClose,
  form,
  onSubmit,
  currentContact,
  type
}: PropsCreate) => {
  function handleCodeComplete(data: any) {
    // eslint-disable-next-line no-console
    console.log(data);
  }

  const isCreating = !currentContact.verified && !currentContact.value;

  const staticContent = staticContents[type];

  if (!staticContent) return <></>;

  return (
    <Dialog
      onSubmit={onSubmit}
      open={true}
      onClose={onClose}
      title={staticContent.title as string}
      description={
        isCreating ? staticContent.description : 'Enter the verification number sent to you'
      }
      actions={[
        {
          action: () => onClose(),
          label: 'Cancel',
          startIcon: null,
          variant: 'outlined'
        },
        {
          type: 'submit',
          label: isCreating ? 'Advance' : 'Save',
          startIcon: null,
          variant: 'contained'
        }
      ]}>
      {isCreating && staticContent.Form ? (
        <staticContent.Form {...staticContent} type={type} form={form} />
      ) : (
        <Box display="flex" flexDirection="column" gridGap={18} justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gridGap={18}
            justifyContent="center">
            <ReactCodeInput key={2000} fields={4} onComplete={handleCodeComplete} />
          </Box>
          <Typography>
            Problems with verification? click to{' '}
            <Button style={{ padding: 0, margin: 0, fontSize: 16 }} startIcon={null} variant="text">
              resend
            </Button>{' '}
            the code.
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};
