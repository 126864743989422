import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Email(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}>
      <path
        fill="#c0874a"
        d="M511.401 173.663L502.513 179.269 458.244 207.066 268.606 326.073 266.213 327.652 265.699 327.968 256.043 334.048 255.701 334.207 247.753 329.232 246.299 328.284 66.659 215.516 54.439 207.856 0.598 174.057 0 173.663 54.439 139.469 249.548 16.988 261.854 16.988 458.244 140.258 464.74 144.365z"
      />
      <path
        d="M467.859 16.065v383.862c0 8.807-7.88 15.985-17.641 15.985H62.29c-9.671 0-17.551-7.178-17.551-15.985V16.065C44.739 7.176 52.619 0 62.29 0h387.928c9.76 0 17.641 7.176 17.641 16.065z"
        fill="#69b25f"
      />
      <path fill="#a2cc86" d="M110.787 75.101H301.295V100.479H110.787z" />
      <path fill="#53a654" d="M110.787 71.093H301.295V96.471H110.787z" />
      <path fill="#a2cc86" d="M110.787 214.682H400.288V240.06H110.787z" />
      <path fill="#53a654" d="M110.787 210.675H400.288V236.053H110.787z" />
      <path fill="#a2cc86" d="M110.787 284.473H400.288V309.851H110.787z" />
      <path fill="#53a654" d="M110.787 280.466H400.288V305.844H110.787z" />
      <path fill="#a2cc86" d="M110.787 144.891H400.288V170.269H110.787z" />
      <path fill="#53a654" d="M110.787 140.884H400.288V166.262H110.787z" />
      <path
        fill="#f0ba7d"
        d="M256.299 322.118L253.308 323.881 195.45 357.662 188.356 361.847 12.392 464.583 0.598 471.413 0.598 172.824 71.446 214.169 246.642 316.464 248.009 317.272 256.043 321.971z"
      />
      <g>
        <path
          d="M510.236 167.293c.97-.567 1.764-.111 1.764 1.013v294.576c0 1.124-.019 2.027-.043 2.006s-.139-.103-.257-.184a17.202 17.202 0 00-.385-.257.804.804 0 00-.214-.11c-.024 0-.837-.464-1.807-1.03L265.498 320.993c-.97-.566-2.556-1.497-3.524-2.068l-.839-.495a895.743 895.743 0 01-3.298-1.956c-.846-.505-.743-1.379.229-1.944l6.209-3.604 1.895-1.099.257-.147c.071-.04.923-.535 1.895-1.1l5.44-3.161c.972-.564 2.56-1.49 3.531-2.057l232.943-136.069z"
          fill="#e5a864"
        />
      </g>
      <circle cx={377.142} cy={75.101} r={38.186} opacity={0.68} fill="#eef3ca" />
      <path
        d="M512 425.251v45.808h-.513l-7.35.342H27.689l-.341-7.094c8.632-5.896 20.082-13.845 27.518-18.887l1.025-.684 24.956-17.178 38.97-26.92 11.28-7.777 118.365-81.615 7.947-5.556c1.88-1.195 4.017-2.222 6.324-2.904 2.735-.941 5.726-1.539 8.717-1.795 1.025-.086 1.965-.171 2.991-.086 1.025-.171 2.052-.171 3.077-.171a34.91 34.91 0 017.948.683c2.734.513 5.213 1.369 7.435 2.564l89.735 49.91 44.354 24.697 11.538 6.41 41.79 23.245 27.518 15.298c.77.428 1.624.855 2.479 1.368.172.086.428.257.685.342z"
        fill="#c0874a"
      />
      <path
        d="M512 441.404v29.997H.598v-29.997c8.974-5.471 20.768-12.905 28.459-17.52l1.025-.684 25.724-15.98 40.252-24.955 11.623-7.264 122.125-75.805 8.204-5.127c4.273-2.564 9.828-3.846 15.298-3.932a17.37 17.37 0 012.991 0 18.38 18.38 0 013.077 0c2.393 0 4.701.257 6.923.769 3.077.599 5.896 1.71 8.29 3.163l11.879 7.349 75.377 46.834 43.073 26.75 11.196 7.007 40.68 25.212 26.748 16.665c.77.428 1.539.854 2.393 1.367 5.47 3.505 12.477 7.864 19.058 11.88a174.72 174.72 0 007.007 4.271z"
        fill="#ecb168"
      />
      <path
        d="M467.816 16.066v4.273c0-8.887-7.863-16.066-17.605-16.066H62.301c-9.656 0-17.604 7.178-17.604 16.066v-4.273C44.696 7.178 52.645 0 62.301 0h387.911c9.741 0 17.604 7.178 17.604 16.066z"
        opacity={0.2}
        fill="#badb9e"
      />
    </SvgIcon>
  );
}
