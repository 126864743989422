import { Account } from 'types/external/Account';
import { useQuery } from 'urql';

type UseAccountListParams = {
  page: number;
  perPage: number;
  query?: string;
  aggregationKeys?: string;
  aggregationRefs?: string;
  aggregationValues?: string;
  termKey?: string;
  termValue?: string[];
  ignoreDate?: string;
  pause?: boolean;
};

type AccountListData = {
  data: Account[];
  total: number;
};

const AccountListQuery = `#graphql
  query(
    $from: Int!
    $size: Int!
    $query: String!
    $aggregationRefs: String
    $aggregationKeys: String!
    $aggregationValues: String
    $termKey: String
    $termValue: [String!]
    $ignoreDate: String
  ) {
    accountList(
      from: $from,
      size: $size,
      query: $query
      aggregationRefs: $aggregationRefs
      aggregationKeys: $aggregationKeys
      aggregationValues: $aggregationValues
      termKey: $termKey
      termValue: $termValue
      ignoreDate: $ignoreDate
    ) {
      data {
        id
        userId
        name
        email
        active
        orgUid
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export function useAccountList({
  page,
  perPage: size,
  query,
  aggregationKeys,
  aggregationRefs,
  aggregationValues,
  termKey,
  termValue,
  ignoreDate,
  pause = false
}: UseAccountListParams) {
  const from = Math.max(page - 1, 0) * size;

  const [{ fetching, data }, reexecuteQuery] = useQuery<{ accountList: AccountListData }>({
    query: AccountListQuery,
    variables: {
      from,
      size,
      query,
      aggregationKeys,
      aggregationRefs,
      aggregationValues,
      termKey,
      termValue,
      ignoreDate
    },
    pause
  });

  return {
    fetching,
    data: {
      accounts: data?.accountList?.data || [],
      total: data?.accountList?.total || 0
    },
    reexecuteQuery
  };
}
