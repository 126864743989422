import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    '& a': {
      color: 'inherit',
      display: 'block',
      width: '100%'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.green[50],
      color: theme.palette.green[400]
    }
  }
}));
