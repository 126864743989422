import { Avatar } from '@material-ui/core';
import { TableColumn } from 'react-data-table-component';
import { Account } from 'types/external/Account';

// import { capitalizeFirstLetter } from 'views/ServicesHub/forms/User';

export const columns = ({
  editIcon,
  deleteIcon
}: {
  editIcon: (id: number) => void;
  deleteIcon: (id: number) => void;
}): TableColumn<Account>[] => {
  return [
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => <Avatar src={row?.photoUrl}>{row?.name}</Avatar>
    },
    {
      name: 'Name',
      sortable: true,
      cell: row => row?.name
    },
    {
      name: 'Email',
      sortable: true,
      cell: row => row?.email
    },
    {
      name: 'Status',
      sortable: true,
      cell: row => (row?.active ? 'Active' : 'Inactive')
    },
    // {
    //   name: 'Role',
    //   cell: row => capitalizeFirstLetter(row.role.name)
    // },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: (row: { userId: number }) => editIcon(row?.userId)
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: (row: { userId: number }) => deleteIcon(row?.userId)
    }
  ];
};
