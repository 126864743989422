import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { Metrics } from '../Metrics';

const GetDowntimeInsightsQuery = `#graphql
    query(
      $startDate: String!
      $endDate: String!
    ){
        insights{
            monitoringPerformance {
                downtime(startDate: $startDate, endDate: $endDate)
            }
        }
    }
`;

export const DowntimeMetrics = () => {
  const endDate = dayjs().format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery({
    query: GetDowntimeInsightsQuery,
    variables: {
      startDate,
      endDate
    }
  });

  const Downtime = data?.insights?.monitoringPerformance?.downtime;

  return (
    <Metrics
      name="Downtime"
      value={`${Downtime}`}
      tooltipTitle="Time of inactivity in your monitoring."
      footer="Real-time data"
      isFetching={fetching}
    />
  );
};
