import { useRef, useState } from 'react';

import { Avatar, Badge, Box, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import momentTimezone from 'moment-timezone';
import { UseFormMethods } from 'react-hook-form';

import { Button } from 'components/Button';
import Dialog from 'components/Dialog';
import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';

import { useStyles } from './styles';

export type FormInputs = {
  timezone: string;
  name: string;
};

type Props = {
  form: UseFormMethods<FormInputs>;
};

const FormProfile = ({ form }: Props) => {
  const [isOpenAvatarDialog, setIsOpenAvatarDialog] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const classes = useStyles({
    isDragging
  });

  const onFileLoad = (e: any, file: any) => {
    setAvatarUrl(e.target.result);
  };

  const onInputChange = (e: any) => {
    setIsDragging(false);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => onFileLoad(e, file);
    reader.readAsDataURL(file);
  };

  const handleUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onDragOver = (props: any) => {
    setIsDragging(true);
  };
  const onDragLeave = (props: any) => {
    setIsDragging(false);
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      <Box display="flex" flexDirection="column" gridGap="1rem">
        <Box gridGap={16} mb={2} display="flex" alignItems="center" flexDirection="center">
          <Box>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              badgeContent={
                <Box className={classes.smallBox}>
                  <EditOutlinedIcon />
                </Box>
              }>
              <Avatar
                onClick={() => setIsOpenAvatarDialog(true)}
                className={classes.avatar}
                alt="Travis Howard"
                src="/static/images/avatar/2.jpg"
              />
            </Badge>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">Vitor França</Typography>
            <Typography className={classes.locationText} color="textPrimary" variant="body1">
              <strong>E-mail:</strong> vitor.franca@elven.works
            </Typography>
          </Box>
        </Box>

        <Input
          label="Name"
          name="name"
          inputRef={form.register}
          required={false}
          // errors={errors}
        />
        <Select
          defaultValue={momentTimezone.tz.guess()}
          options={momentTimezone.tz.names().map(tz => ({
            label: tz,
            value: tz
          }))}
          control={form.control}
          name="timezone"
          label="Timezone"
          required={true}
        />
      </Box>
      <Box display="flex" gridGap="1rem">
        <Button startIcon={null} variant="contained" color="primary" type="submit" disabled={false}>
          SAVE
        </Button>
      </Box>
      <Dialog
        title="Upload photo"
        open={isOpenAvatarDialog}
        onClose={() => setIsOpenAvatarDialog(false)}
        actions={[
          {
            label: 'Cancel',
            variant: 'outlined',
            action: () => setIsOpenAvatarDialog(false)
          },
          {
            label: 'Save'
          }
        ]}>
        <input
          accept=".png, .jpg, .jpeg"
          id="avatarAccount"
          onChange={onInputChange}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          ref={inputRef}
          className={classes['input-file']}
          type="file"
        />
        <Box className={classes.dragAndDropBox}>
          {!avatarUrl ? (
            <>
              <Typography>Drag and drop your image here</Typography>
              <Typography>Or</Typography>
              <Button onClick={handleUpload} startIcon={null} variant="contained">
                Choose a file
              </Button>
            </>
          ) : (
            <img src={avatarUrl} alt="Image_uploaded" />
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default FormProfile;
