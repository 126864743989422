import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { Metrics } from '../Metrics';

export const IncidentsTotal = () => {
  const GetIncidentsTotal = `#graphql
    query (
      $startDate: String! 
      $endDate: String!      
    ) {
      insights {
        incidents {
          total(startDate: $startDate, endDate: $endDate)
        }      
      }
    }
  `;

  const endDate = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery({
    query: GetIncidentsTotal,
    variables: {
      startDate,
      endDate
    }
  });

  const incidentsTotal = data?.insights?.incidents?.total;

  return (
    <Metrics
      name="Total incidents"
      value={incidentsTotal}
      footer="Metrics calculated up to yesterday"
      isFetching={fetching}
      tooltipTitle="Total number of incidents that occurred in the organization over a specific period."
    />
  );
};
