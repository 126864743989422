import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import UserList from './UserList';

import { Users } from '.';

export const SwitchUserList = () => {
  const useNewUsersList = useFeatureFlagUnleash('useNewUsersList', { queryString: true });

  if (useNewUsersList) {
    return <Users />;
  }

  return <UserList />;
};
