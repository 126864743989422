import { Badge, Box, Button, TextField, useTheme } from '@material-ui/core';
import { Search, Tune } from '@material-ui/icons';

import MemberList from './List';
import { useStyles } from './styles';

const MemberTab = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={3}
          mt={1}
          gridGap={theme.spacing(1.5)}>
          <TextField
            variant="outlined"
            label="Search"
            name="search"
            placeholder="Search for name"
            InputProps={{
              endAdornment: <Search />,
              className: classes.input
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
          />
          <Badge
            classes={{ badge: classes.badgeContent }}
            color="primary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Button variant="outlined" className={classes.button}>
              <Box display="flex" alignItems="center" gridGap={8}>
                <Tune />
                Filters
              </Box>
            </Button>
          </Badge>
        </Box>

        <MemberList />
      </Box>
    </>
  );
};

export default MemberTab;
