import { useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { useStyles } from './styles';

export function MenuActions() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeMember = () => {
    handleClose();
  };

  const editMember = () => {
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem className={classes.menuItem} onClick={editMember}>
          Edit
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={removeMember}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
