import { ExternalServiceBaseForm } from 'types/external/ExternalService';

export function externalServiceAdapter(data: ExternalServiceBaseForm) {
  const responders = data?.responders?.map(responder => {
    return {
      responderId: Number(responder?.value)
    };
  });

  return {
    name: data.name,
    responders
  };
}

export function externalServiceOriginAdapter(str: string) {
  return str === 'mongodb' ? 'mongodb-atlas' : str;
}
