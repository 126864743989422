import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { Metrics } from '../Metrics';

const GetAvgLatencyQuery = `#graphql
  query(
    $startDate: String!
    $endDate: String!
  ){
    insights{
      monitoringPerformance {
        avgLatency(startDate: $startDate, endDate: $endDate)
      }
    }
  }
`;

export const AvgLatencyMetrics = () => {
  const endDate = dayjs().format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery({
    query: GetAvgLatencyQuery,
    variables: {
      startDate,
      endDate
    }
  });

  const avgLatency = data?.insights?.monitoringPerformance?.avgLatency;

  const formatAvgLatency = () => {
    if (typeof avgLatency !== 'number' || avgLatency === -1) {
      return '-';
    }

    return `${avgLatency}ms`;
  };

  return (
    <Metrics
      name="Avg Hour Latency"
      value={formatAvgLatency()}
      tooltipTitle="Average response time (latency) in monitoring."
      footer="Real-time data"
      isFetching={fetching}
    />
  );
};
