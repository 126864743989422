import { Box } from '@material-ui/core';

import NotificationsRules from 'components/NotificationRules';
import { Contacts } from 'views/Account/components/ContactMethod';

const rulesMock: Contacts = [
  {
    method: 'email',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'whatsapp',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'voice',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'sms',
    value: '+55-71986237773',
    active: true,
    verified: true
  },
  {
    method: 'telegram',
    value: '8928383',
    active: true,
    verified: true
  },
  {
    method: 'app',
    value: 'iphone j. Laricia - iphone 12 - v.1',
    active: true,
    verified: true
  },
  {
    method: 'all' as any,
    value: '',
    active: true,
    verified: true
  }
];

const NotificationsRulesTab = () => {
  return (
    <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
      <NotificationsRules contactMethods={rulesMock} />
    </Box>
  );
};

export default NotificationsRulesTab;
